<template>
  <div class="modal">
    <div class="backdrop" />

    <div class="content">
      <slot name="header">
        <h5>{{ title }}</h5>
        <p v-if="subtitle">{{ subtitle }}</p>
      </slot>

      <div v-if="$slots.body" class="d-flex flex-col gap-2 py-1">
        <slot name="body" />
      </div>

      <Errors :errors="errors" />

      <div v-if="$slots.footer" class="d-flex flex-col gap-2">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Errors from "@/components/Errors";

export default {
  name: "Modal",
  components: { Errors },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    }
  },
  computed: {
    ...mapState(["errors"])
  }
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  inset: 0;
  display: flex;
  padding: 2rem;
  justify-content: center;
  align-items: center;

  .backdrop {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  .content {
    z-index: 10;
    padding: 1rem;
    background: $white;
    border-radius: 0.5em;
    box-shadow: 0 0 1.75em -0.25em rgba(0, 0, 0, 0.5);

    h5 {
      margin: 1rem;
      font-size: 1.25rem;
      font-weight: 700;
      text-align: center;
    }

    p {
      margin: 1rem;
      margin-bottom: 2rem;
      color: $light-grey;
      text-align: center;
    }
  }
}
</style>
