<template>
  <Modal :title="title" :subtitle="subtitle">
    <template v-slot:footer>
      <div class="d-flex flex-col gap-2">
        <a
          href="#"
          class="btn btn-primary btn-green"
          @click.prevent="handleConfirm"
        >
          {{ confirmText || $t("buttonActions.confirm") }}
        </a>
        <a
          href="#"
          class="btn btn-primary btn-white"
          @click.prevent="handleCancel"
        >
          {{ cancelText || $t("buttonActions.cancel") }}
        </a>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "OverlayConfirm",
  components: { Modal },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    confirmText: {
      type: String
    },
    cancelText: {
      type: String
    }
  },

  methods: {
    handleConfirm() {
      this.$emit("confirm");
    },

    handleCancel() {
      this.$emit("cancel");
    }
  }
};
</script>
